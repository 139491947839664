import {AxiosError} from 'axios'
import {useQuery} from 'react-query'

import {isUserLoggedIn} from '../../../authentication'
import {SlotUser} from '../../../types'
import {queries} from '../../queries'
import {useErrorHandling} from '../useErrorHandling'

export const useLoadUser = (user?: SlotUser) => {
  const {handleApiUnavailable, handleUnauthorized, handleNetworkError} = useErrorHandling()
  return useQuery('user', queries.user, {
    onError: (err: AxiosError) => {
      const isNetworkError = err.message === 'Network Error'
      const status = err.response?.status

      if (isNetworkError) handleNetworkError('user')
      if (status === 401) handleUnauthorized()
      if (status === 503) handleApiUnavailable()
    },
    enabled: !isUserLoggedIn(user)
  })
}
