import {AccountDropdown} from '@hconnect/uikit'
import {HeaderContainer as ShellHeader} from '@hconnect/uikit/src/lib2'
import {Grid, Typography, styled} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Link} from 'react-router-dom'

import {navigation} from '../common/navigation'
import {useAuthContext} from '../providers/AuthProvider'

const UnstyledLink = styled(Link)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`

export const Header: React.FC = () => {
  const {t} = useTranslation()
  const {logout, user} = useAuthContext()
  return (
    <ShellHeader>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <UnstyledLink to="/">
                <Typography color="black" variant="h5" component="span">
                  {t('appName')}
                </Typography>
              </UnstyledLink>
            </Grid>
            <Grid item>
              {Object.values(navigation).map(({labelKey, route, hidden}) => {
                if (hidden) return null
                return (
                  <Link key={labelKey} to={route}>
                    {t(labelKey)}
                  </Link>
                )
              })}
            </Grid>
          </Grid>
        </Grid>

        {user ? (
          <Grid item>
            <AccountDropdown
              profile={{
                name: user.name,
                email: user.email
              }}
              actions={{
                logout
              }}
              logoutButtonText={t('logout')}
            />
          </Grid>
        ) : null}
      </Grid>
    </ShellHeader>
  )
}
