import {ReactComponent as LogoHConnectComponent} from '@hconnect/uikit/src/common/assets/LogoHConnect.svg'
import {ShellFooter} from '@hconnect/uikit/src/lib2'
import {Box} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'

export const Footer: React.FC = () => {
  const {t} = useTranslation()
  return (
    <ShellFooter
      label={t('appName')}
      logo={
        <Box component="span" display="inline-flex" mb={'1px'}>
          <LogoHConnectComponent height={14} />
        </Box>
      }
      copyrightNote={t('copyrightNotice', {
        year: new Date().getFullYear()
      })}
    />
  )
}
