/* eslint-disable @typescript-eslint/naming-convention */
import {useNotification} from '@hconnect/uikit'
import {AxiosError} from 'axios'
import {format} from 'date-fns'
import {useTranslation} from 'react-i18next'
import {useMutation} from 'react-query'

import {trackEvent} from '../../../analytics'
import {MutationSlotContext, SlotUpdateErrorResponse} from '../../../types'
import {mutations} from '../../mutations'
import {slotQueryClient} from '../../QueryProvider'
import {useErrorHandling} from '../useErrorHandling'

export const useUpdateSlot = () => {
  const {notify} = useNotification()
  const {t} = useTranslation()
  const {
    handleApiUnavailable,
    handleBadRequest,
    handleExistingSlot,
    handleNetworkError,
    handleNoPermissions,
    handleUnauthorized
  } = useErrorHandling()

  return useMutation(mutations.updateSlot, {
    onError: (err: AxiosError<SlotUpdateErrorResponse>, {slot}, context) => {
      const isNetworkError = err.message === 'Network Error'
      const status = err.response?.status

      const notificationData = {
        plantId: slot.plantId,
        date: format(new Date(slot.date), 'dd MMM yyyy'),
        slotName: slot.slotConfigId
      }

      const trackEventData = {
        type: 'update',
        status: slot.status,
        date: slot.date,
        slotName: slot.slotConfigId,
        plantId: slot.plantId,
        errorStatus: err.response?.status
      }

      const frontendErrorTrackEvent = void trackEvent(
        'hubOrderIntakeSlotFrontendError',
        trackEventData
      )

      if (isNetworkError) handleNetworkError('slots')
      if (status === 400 || status === 404 || status === 500)
        handleBadRequest(notificationData, frontendErrorTrackEvent)
      if (status === 403) handleNoPermissions(frontendErrorTrackEvent)
      if (status === 401) handleUnauthorized(frontendErrorTrackEvent)
      if (status === 409) handleExistingSlot(notificationData, 'slots', frontendErrorTrackEvent)
      if (status === 503) handleApiUnavailable(frontendErrorTrackEvent)

      slotQueryClient.setQueryData(['slots'], (context as MutationSlotContext).previousSlots)
    },
    onSuccess: () => {
      notify('success', t('updatedSlot'))
    }
  })
}
