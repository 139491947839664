import {Loader, LandingPage} from '@hconnect/uikit/src/lib2'
import {Typography} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {Route, Switch} from 'react-router'

import {navigation} from './common/navigation'
import {Footer} from './components/Footer'
import {Shell} from './components/Shell'
import {ErrorPage} from './pages/ErrorPage/ErrorPage'
import {SlotsByConfig} from './pages/SlotsByConfig/SlotsByConfig'
import {useAuthContext} from './providers/AuthProvider'

export const Main: React.FC = () => {
  const {isLoggedIn, isLoading, login} = useAuthContext()

  const {t} = useTranslation()

  if (isLoading) {
    return <Loader height="60vh" color="primary.contrastText" />
  }

  if (!isLoggedIn) {
    return (
      <LandingPage
        onClickButton={() => {
          login()
        }}
        loginButtonLabel={t('login.button')}
        footer={<Footer />}
      >
        <Typography variant="h1">{t('appName')}</Typography>
      </LandingPage>
    )
  }

  return (
    <Shell>
      <Switch>
        <Route path={[navigation.SlotsByConfig.route]} exact>
          <SlotsByConfig />
        </Route>
        <Route>
          <ErrorPage />
        </Route>
      </Switch>
    </Shell>
  )
}
