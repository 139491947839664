import React from 'react'
import {DefaultOptions, QueryClient, QueryClientProvider} from 'react-query'

const defaults: DefaultOptions = {
  queries: {
    retry: 3,
    refetchOnWindowFocus: false,
    staleTime: Infinity
  }
}
export const slotQueryClient = new QueryClient({defaultOptions: defaults})

export const QueryProvider = ({children}: {children: React.ReactNode}) => {
  // const raiseError = useErrorHandler()

  const onError = (err) => {
    const response = err.response
    const statusCode = response?.status
    const statusText = response?.data?.status ?? response?.statusText
    const url = response?.request?.responseURL

    /* eslint-disable-next-line */
    const message = [
      `Error occurred querying ${url}`,
      `${statusCode}: ${statusText}`,
      response?.data?.detail
    ].join('\n')
    // Removed raising error to prevent unexpected crashing
    // raiseError(new Error(message))
  }

  slotQueryClient.setDefaultOptions({queries: {...defaults.queries, onError}, mutations: {onError}})

  return <QueryClientProvider client={slotQueryClient}>{children}</QueryClientProvider>
}
