import {Shell as UIKitShell} from '@hconnect/uikit/src/lib2'
import {Box, styled} from '@mui/material'
import React from 'react'

import {Footer} from './Footer'
import {Header} from './Header'

const ChildrenWrapper = styled(Box)(({theme}) => ({
  padding: theme.spacing(4),
  margin: '0 auto',
  maxWidth: '100%'
}))

export const Shell = ({children}: {children: React.ReactNode}) => {
  return (
    <UIKitShell
      boxed={false}
      compact={false}
      showScrollToTop={false}
      isResponsive
      header={<Header />}
      footer={<Footer />}
    >
      <ChildrenWrapper>{children}</ChildrenWrapper>
    </UIKitShell>
  )
}
