export const DAYS_IN_ADVANCE = 15
export const LEGACY_DATE_COLUMN_WIDTH = 160
export const DATE_CELL_HEIGHT = 64
export const ROW_HEIGHT = 58
export const DATE_CELL_WIDTH = 110
export const PLANTS_COL_WIDTH = 350
export const PLANTS_CODE_WIDTH = 40
export const FIXED_HEADER_HEIGHT = 105
export const STATUS_SELECT_WIDTH = 230
export const STATUS_SELECT_HEIGHT = 40
export const AVAILABLE_STATUS = 'Available'
export const FONT_RATIO = 1.4
export const CURRENT_THEME_KEY = 'CurrentThemeKey'
export const DEFAULT_THEME_NAME = 'LARGE'
