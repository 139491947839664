import {createHttpClient, createPublicHttpClient} from '@hconnect/apiclient'
import {
  backendSelector,
  loginStorage,
  authRequestProvider,
  loginFlow
} from '@hconnect/common/authentication/loginClasses'

export const API_PATH = 'slotmanagement'

export const publicApi = createPublicHttpClient({
  backendSelector
})

export const api = createHttpClient({
  backendSelector,
  authRequestProvider,
  loginStorage,
  loginFlow
})
