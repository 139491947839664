{/* eslint-disable */}
import {makeStyles} from '@material-ui/core/styles'
import {KeyboardArrowDown} from '@mui/icons-material'
import {Grid, Select, CircularProgress, lighten, styled, darken, Box} from '@mui/material'
import {format, formatISO} from 'date-fns'
import React, {useContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {v4 as uuidV4} from 'uuid'

import {useUpdateSlot} from '../../common/react-query/hooks/mutations/useUpdateSlot'
import {SlotStatus} from '../../common/types'
import {useAuthContext} from '../../providers/AuthProvider'
import {AVAILABLE_STATUS, DATE_CELL_WIDTH, FONT_RATIO, ROW_HEIGHT} from '../declarations/constants'
import {SlotsThemeContext} from '../SlotsByConfig/SlotsByConfig'

export const statusColor = {
  Available: '#A1ADB8',
  Free: '#38C165',
  Full: '#C9312B',
  Refer: '#576A7D',
  Internal: '#E46259'
}

const SlotSelectWrapper = styled(Grid)<{
  status: string
  loading: number
  isSelected: boolean
  themeRatio: number
}>(({status, loading, isSelected, themeRatio}) => ({
  background: isSelected
    ? darken(statusColor[status], 0.4)
    : loading
    ? lighten(statusColor['Available'], 0.5)
    : statusColor[status],
  width: DATE_CELL_WIDTH * themeRatio,
  height: ROW_HEIGHT * themeRatio,
  pointerEvents: isSelected ? 'none' : 'auto',
  fontSize: 12 * themeRatio * FONT_RATIO
}))
export const SlotSelectComponent = styled(Select)<{themeRatio: number}>`
  border-radius: 0;
  width: 100%;
  height: 100%;
  color: white;
  font-weight: bold;
  border: 1px solid #c9c9c9;
  font-size: ${({themeRatio}) => 16 * themeRatio}px;
  & option,
  & optgroup {
    background-color: ${darken(statusColor['Available'], 0.5)} !important;
  }
`

export const SlotSelectAlwaysFullBox = styled(Box)`
  width: 100%;
  height: 100%;
  color: white;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #c9c9c9;
`

const useStyles = makeStyles(() => ({
  slotSelectStyle: {
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none'
    }
  }
}))

interface Props {
  statuses: SlotStatus[]
  date: Date
  plantId: string
  slotConfigId: string
  slotName: string
  disabled?: boolean
  isSelected: boolean
  slotId?: string
  slotStatus?: SlotStatus
  isUpdate?: boolean
  isAlwaysFull: boolean
  themeRatio: number
}

export const SlotSelect: React.FC<Props> = ({
  statuses,
  slotStatus,
  isUpdate = false,
  isSelected = false,
  date,
  plantId,
  slotName,
  slotConfigId,
  slotId,
  isAlwaysFull,
  themeRatio
}) => {
  const {t} = useTranslation()
  const c = useStyles()
  const {user} = useAuthContext()
  const [statusId, setStatusId] = useState<string>('')
  const {mutate: updateSlot, isLoading: isSlotUpdating} = useUpdateSlot()
  const theme = useContext(SlotsThemeContext)

  useEffect(() => {
    const id = isAlwaysFull ? getStatusId('Full') : slotStatus?.id ?? ''
    setStatusId(id)
  }, [statuses, slotStatus, isAlwaysFull])

  if (!user) return null

  const getStatusName = (statusId: string) => {
    return statuses.find((s) => s.id === statusId)?.statusName ?? AVAILABLE_STATUS
  }

  const getTranslatedStatusName = (statusId: string) => {
    return statuses.find((s) => s.id === statusId)?.translatedStatusName ?? '?'
  }

  const getStatusId = (statusName: string) => {
    return statuses.find((s) => s.statusName === statusName)?.statusName ?? ''
  }

  const handleSelection = (statusId: string) => {
    setStatusId(statusId)
    const statusName = getStatusName(statusId)
    return updateSlot(
      {
        slot: {
          id: isUpdate && slotId ? slotId : uuidV4(),
          slotConfigId,
          status: statusName,
          plantId,
          date: formatISO(date, {representation: 'date'}),
          slotStatusId: statusId === '' ? undefined : statusId
        },
        isUpdate
      },
      {
        onSuccess: () => setStatusId(statusId)
      }
    )
  }

  const statusName = isAlwaysFull ? 'Full' : getStatusName(statusId)
  const translatedStatusName = isAlwaysFull ? 'Full' : getTranslatedStatusName(statusId)

  return (
    <SlotSelectWrapper
      container
      alignContent="center"
      justifyContent="center"
      loading={isSlotUpdating ? 1 : 0}
      status={statusName}
      isSelected={isSelected}
      themeRatio={themeRatio}
    >
      {isSlotUpdating ? (
        <CircularProgress />
      ) : isAlwaysFull || theme === 'EXTRA_SMALL' ? (
        <SlotSelectAlwaysFullBox>{translatedStatusName}</SlotSelectAlwaysFullBox>
      ) : (
        <SlotSelectComponent
          data-test-id={`slot-select-${format(
            date,
            'dd-MM-yyyy'
          )}-${slotName}-${statusName}-${plantId}`}
          value={statusId}
          placeholder={t(`table.${statusName}`)}
          onChange={(e) => handleSelection(e.target.value as string)}
          displayEmpty
          autoWidth
          native
          IconComponent={KeyboardArrowDown}
          title={t(`table.${statusName}`)}
          className={c.slotSelectStyle}
          themeRatio={themeRatio}
        >
          {statuses.map((status) => {
            return (
              <option
                key={`status-${status.statusName}-${slotName}-${status}-${plantId}`}
                value={status.id}
              >
                {status.translatedStatusName}
              </option>
            )
          })}
        </SlotSelectComponent>
      )}
    </SlotSelectWrapper>
  )
}
