import {User} from '@hconnect/apiclient'
import {loginFlow} from '@hconnect/common/authentication/loginClasses'
import {orderBy} from 'lodash'

import {logger} from '../logger'
import {Plant, Slot, SlotUser, SlotConfiguration, SlotStatus} from '../types'

import {api, API_PATH} from './api'

export const queries = {
  slotConfigurations: async (): Promise<SlotConfiguration[]> => {
    const path = '/SlotConfiguration/configurations?country=GB'
    const response = await api.get<SlotConfiguration[]>(path)
    return orderBy(response.data, 'slotPosition', 'asc')
  },
  slotStatuses: async (): Promise<SlotStatus[]> => {
    const path = '/SlotConfiguration/statuses'
    const response = await api.get<SlotStatus[]>(path)
    return response.data
  },
  plants: async (): Promise<Plant[]> => {
    const path = `${API_PATH}/plants`
    const response = await api.get<Plant[]>(path)
    return response.data
  },
  slots: async (): Promise<Slot[]> => {
    const path = `${API_PATH}/slots`
    const response = await api.get<Slot[]>(path)
    return response.data
  },
  user: async (): Promise<SlotUser | undefined> => {
    const log = logger.context('loadUser')
    const loginResponse = await loginFlow.getLoginState()
    if (!loginResponse.loggedIn) {
      return undefined
    }

    const {user_id: userId, email} = loginResponse.decodedToken

    if (!userId || !email) {
      log.warning(
        'Loading the user failed, because the token does not contain the user ID and email.'
      )
      return undefined
    }
    const path = `/users/${userId}`
    const response = await api.get<User>(path)

    const slotUser: User = response.data
    if (!slotUser.name) {
      log.warning('Loading the user failed, because the name is empty.')
      return undefined
    }

    return {
      userId,
      email,
      name: slotUser.name,
      roles: slotUser.hasRoles ?? [],
      defaultLocale: slotUser.defaultLocale
    }
  }
} as const
