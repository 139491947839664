import {AxiosError} from 'axios'
import {useQuery} from 'react-query'

import {queries} from '../../queries'
import {useErrorHandling} from '../useErrorHandling'

export const useGetSlotConfigurations = () => {
  const {handleApiUnavailable, handleNetworkError, handleUnauthorized} = useErrorHandling()
  return useQuery('slotConfigurations', queries.slotConfigurations, {
    onError: (err: AxiosError) => {
      const isNetworkError = err.message === 'Network Error'
      const status = err.response?.status

      if (isNetworkError) handleNetworkError('plants')
      if (status === 401) handleUnauthorized()
      if (status === 503) handleApiUnavailable()
    }
  })
}
