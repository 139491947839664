/* eslint-disable @typescript-eslint/naming-convention */
import {useNotification} from '@hconnect/uikit'
import {AxiosError} from 'axios'
import {useTranslation} from 'react-i18next'
import {useMutation} from 'react-query'

import {trackEvent} from '../../../analytics'
import {SlotUpdateErrorResponse} from '../../../types'
import {mutations} from '../../mutations'
import {useErrorHandling} from '../useErrorHandling'

export const useBulkUpdateSlot = () => {
  const {notify} = useNotification()
  const {t} = useTranslation()
  const {
    handleApiUnavailable,
    handleBadRequest,
    handleExistingSlot,
    handleNetworkError,
    handleNoPermissions,
    handleUnauthorized
  } = useErrorHandling()

  return useMutation(mutations.bulkUpdateSlot, {
    onError: (err: AxiosError<SlotUpdateErrorResponse>) => {
      const isNetworkError = err.message === 'Network Error'
      const responseStatus = err.response?.status

      const notificationData = {}

      const trackEventData = {
        type: 'bulkChange'
      }

      const frontendErrorTrackEvent = void trackEvent(
        'hubOrderIntakeSlotFrontendError',
        trackEventData
      )

      if (isNetworkError) handleNetworkError('slots')

      if (responseStatus === 400 || responseStatus === 404 || responseStatus === 500)
        handleBadRequest(notificationData, frontendErrorTrackEvent)
      if (responseStatus === 403) handleNoPermissions(frontendErrorTrackEvent)
      if (responseStatus === 401) handleUnauthorized(frontendErrorTrackEvent)
      if (responseStatus === 409)
        handleExistingSlot(notificationData, 'slots', frontendErrorTrackEvent)
      if (responseStatus === 503) handleApiUnavailable(frontendErrorTrackEvent)
    },
    onSuccess: () => {
      notify('success', t('updatedSlots'))
    }
  })
}
