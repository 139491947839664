import React, {createContext, useContext, useEffect, useMemo, useState} from 'react'
import {usePromise} from 'react-use'

import {isUserLoggedIn, startLoginProcess, startLogoutProcess} from '../common/authentication'
import {useLoadUser} from '../common/react-query/hooks/queries/useLoadUser'
import {slotQueryClient} from '../common/react-query/QueryProvider'
import {AuthContext, SlotUser} from '../common/types'

const Context = createContext<AuthContext | undefined>(undefined)

export const useAuthContext = (): AuthContext => {
  const context = useContext(Context)
  if (!context) {
    throw new Error('useAuthContext used outside of AuthProvider')
  }
  return context
}

export const AuthProvider = ({children}: {children: React.ReactNode}) => {
  const {data, isLoading} = useLoadUser()
  const [user, setUser] = useState<SlotUser | undefined>(data)
  const promise = usePromise()

  useEffect(() => {
    const invalidateUserQuery = async () => {
      await slotQueryClient.invalidateQueries(['user'])
    }
    if (!data) void invalidateUserQuery()
    setUser(data)
  }, [data])

  const value = useMemo(() => {
    const logout = async () => {
      await promise(startLogoutProcess())
      setUser(undefined)
    }

    return {
      user,
      login: startLoginProcess,
      logout,
      isLoading: isLoading,
      isLoggedIn: isUserLoggedIn(user)
    }
  }, [isLoading, promise, user])

  return <Context.Provider value={value}>{children}</Context.Provider>
}
