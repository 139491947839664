import {Loader} from '@hconnect/uikit/src/lib2'
import {makeStyles} from '@material-ui/core/styles'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import {Paper, Grid, Typography, Box, styled, FormControlLabel} from '@mui/material'
import Checkbox from '@mui/material/Checkbox'
import {eachDayOfInterval, format, parseISO} from 'date-fns'
import {isEmpty} from 'lodash'
import React, {createContext, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'

import {useBulkUpdateSlot} from '../../common/react-query/hooks/mutations/useBulkUpdateSlot'
import {useGetPlants} from '../../common/react-query/hooks/queries/useGetPlants'
import {useGetSlotConfigurations} from '../../common/react-query/hooks/queries/useGetSlotConfigurations'
import {useGetSlots} from '../../common/react-query/hooks/queries/useGetSlots'
import {useGetSlotStatuses} from '../../common/react-query/hooks/queries/useGetSlotStatuses'
import {slotQueryClient} from '../../common/react-query/QueryProvider'
import {Slot, SlotBulkDay, SlotConfiguration, SlotStatus, SlotTheme} from '../../common/types'
import {Bold} from '../components/Bold'
import {SlotSelect} from '../components/SlotSelect'
import {StatusSelect} from '../components/StatusSelect'
import {ThemeSelect} from '../components/ThemeSelect'
import {
  DATE_CELL_HEIGHT,
  DATE_CELL_WIDTH,
  DAYS_IN_ADVANCE,
  PLANTS_COL_WIDTH,
  FIXED_HEADER_HEIGHT,
  ROW_HEIGHT,
  AVAILABLE_STATUS,
  FONT_RATIO,
  CURRENT_THEME_KEY,
  DEFAULT_THEME_NAME,
  PLANTS_CODE_WIDTH
} from '../declarations/constants'
import {SlotByPlantId} from '../declarations/types'
import {mergeSlots, mostRecentSlot} from '../util'

const useStyles = makeStyles(() => ({
  isUpdating: {
    pointerEvents: 'none',
    opacity: 0.8
  },
  plantNameWrapper: {
    width: `calc(100% - ${PLANTS_CODE_WIDTH}px)`
  },
  plantName: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}))

const BoldHeadCell = styled(Grid)(({theme}) => ({
  fontWeight: theme.typography.fontWeightBold
}))

const SlotsGridContainer = styled(Grid)(({theme}) => ({
  display: 'grid',
  overflow: 'auto',
  height: 'calc(100vh - 295px)',
  width: '100%',
  '::-webkit-scrollbar': {
    width: 8,
    height: 8
  },
  '::-webkit-scrollbar-thumb': {
    backgroundColor: `${theme.palette.grey[400]}`,
    border: '4px solid rgba(0, 0, 0, 0)',
    borderRadius: `${theme.shape.borderRadius}px`
  },
  '::-webkit-scrollbar-track': {
    backgroundColor: `${theme.palette.grey[200]}`,
    borderRadius: `${theme.shape.borderRadius}px`
  }
}))

const SlotsGrid = styled(Box)({
  display: 'flex',
  flexWrap: 'nowrap'
})

const SlotsGridBorderCol = styled(Box)(({theme}) => ({
  borderLeft: `4px solid ${theme.palette.background.default}`
}))

const SlotsGridFixedCol = styled(Box)<{themeratio: number}>(({themeratio}) => ({
  width: PLANTS_COL_WIDTH * themeratio,
  position: 'sticky',
  left: 0,
  zIndex: 999,
  background: 'white',
  borderRadius: '4px 0 0 0'
}))

const SlotsGridItem = styled(Box)<{themeratio: number}>(({themeratio}) => ({
  height: ROW_HEIGHT * themeratio
}))

const SlotsGridFixedHeader = styled(Box)({
  height: `${FIXED_HEADER_HEIGHT}px`,
  position: 'sticky',
  top: 0,
  background: 'white',
  zIndex: 99
})

const SlotsTableDateCell = styled(Grid)<{loading: number}>`
  padding-top: 10px;
  padding-bottom: 10px;
  padding-bottom: 10px;
  border-left: ${({theme}) => `1px solid ${theme.palette.grey[100]}`};
  height: ${({loading}) => (loading ? DATE_CELL_HEIGHT - 4 : DATE_CELL_HEIGHT)}px;
`

const SlotsFormControlLabel = styled(FormControlLabel)<{
  themeratio: number
  slotsthemename: string
}>(({themeratio, slotsthemename}) => ({
  marginLeft: '0px',
  marginRight: '0px',
  width: DATE_CELL_WIDTH * themeratio,
  flexDirection: slotsthemename === 'SMALL' ? 'column' : 'row-reverse'
}))

const PlantColumn = styled(Grid)<{index: number; count: number; themeratio: number}>(
  ({theme, index, count, themeratio}) => ({
    height: ROW_HEIGHT * themeratio,
    borderTop: `${index === 0 ? 2 : 1}px solid ${theme.palette.grey[200]}`,
    borderBottom: `${index === count - 1 ? `1px solid ${theme.palette.grey[100]}` : null}`
  })
)

const TableHeadColumns = styled(Grid)<{themeratio: number}>(({theme, themeratio}) => ({
  height: `${FIXED_HEADER_HEIGHT + 1}px`,
  width: `${PLANTS_COL_WIDTH * themeratio}px`,
  borderBottom: `1px solid ${theme.palette.grey[200]}`
}))

const StatusSelectContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '16px 0px'
})

export const SlotsThemeContext = createContext('EXTRA')

export const SlotsByConfig: React.FC = () => {
  const {t} = useTranslation()
  const c = useStyles()

  const {data: slotConfigurations, isLoading: isSlotConfigurationLoading} =
    useGetSlotConfigurations()
  const {data: slotStatusesFromBE, isLoading: isSlotStatusesLoading} = useGetSlotStatuses()

  const {data: plants, isLoading: isPlantsLoading} = useGetPlants()
  const {data: slots, isLoading: isSlotsLoading, isFetching: isSlotsFetching} = useGetSlots()
  const {mutate: bulkSlotsUpdate} = useBulkUpdateSlot()

  const [slotStatuses, setSlotStatuses] = useState<SlotStatus[]>([])
  const [selectedConfigurations, setSelectedConfigurations] = useState<boolean[][]>([])
  const slotsByPlants: SlotByPlantId[] = (plants || []).map(({plantId}) => ({
    plantId,
    slots: slots?.filter((slot) => slot.plantId === plantId)
  }))

  const [isSlotUpdating, setIsSlotUpdating] = useState<boolean>(false)
  const [isBulkChangeEnabled, setIsBulkChangeEnabled] = useState<boolean>(false)
  const [currentTheme, setCurrentTheme] = useState<string>(DEFAULT_THEME_NAME)
  const [currentThemeRatio, setCurrentThemeRatio] = useState<number>(1)
  const [renderingAfterThemeChange, setRenderingAfterThemeChange] = useState<boolean>(false)
  const dates = eachDayOfInterval({
    start: new Date(),
    // to ensure that the date array includes the start day, we deduct - 1
    end: new Date().setDate(new Date().getDate() + DAYS_IN_ADVANCE - 1)
  })

  const SlotsApplicationThemes: SlotTheme[] = [
    {themeName: t('themes.large'), id: 'LARGE'},
    {themeName: t('themes.small'), id: 'SMALL'},
    {themeName: t('themes.extraSmall'), id: 'EXTRA_SMALL'}
  ]

  useEffect(() => {
    const themeName = localStorage.getItem(CURRENT_THEME_KEY) ?? DEFAULT_THEME_NAME
    const themeRatio = getThemeRatio(themeName)

    setCurrentThemeRatio(themeRatio)
    setCurrentTheme(themeName)
  }, [])

  useEffect(() => {
    initializeSelectedConfigurations()
  }, [slotConfigurations])

  useEffect(() => {
    if (slotStatusesFromBE) {
      const resolvedSlotStatuses: SlotStatus[] = []
      resolvedSlotStatuses.push({
        id: '',
        translatedStatusName: t('table.Available'),
        statusName: AVAILABLE_STATUS,
        isBlockingDelivery: false
      })
      slotStatusesFromBE.forEach((item) => {
        resolvedSlotStatuses.push({...item, translatedStatusName: t(`table.${item.statusName}`)})
      })
      setSlotStatuses(resolvedSlotStatuses)
    }
  }, [slotStatusesFromBE])

  useEffect(() => {
    if (renderingAfterThemeChange) {
      requestAnimationFrame(() => {
        setTimeout(() => {
          setRenderingAfterThemeChange(false)
        })
      })
    }
  }, [renderingAfterThemeChange])

  const getSelectedConfigurations = (): SlotBulkDay[] => {
    const dateConfigs: SlotBulkDay[] = []

    if (!slotConfigurations) return dateConfigs

    dates.forEach((date, dateIndex) => {
      const existSeletecItems = selectedConfigurations[dateIndex].some((item) => item)
      if (existSeletecItems) {
        const slotBulkDay = {} as SlotBulkDay
        slotBulkDay.date = format(date, 'MM-dd-yyyy')
        slotBulkDay.slotConfigIds = []

        slotConfigurations.forEach((slotConfig, slotConfigIndex) => {
          if (selectedConfigurations[dateIndex][slotConfigIndex]) {
            slotBulkDay.slotConfigIds.push(slotConfig.id)
          }
        })

        dateConfigs.push(slotBulkDay)
      }
    })

    return dateConfigs
  }

  const getAllPlants = (): string[] => {
    if (!plants) return {} as string[]

    return plants.map((item) => item.plantId)
  }

  const initializeSelectedConfigurations = () => {
    const selectedConfigs = new Array<boolean[]>(dates.length)
    dates.forEach((date, dateIndex) => {
      selectedConfigs[dateIndex] = new Array<boolean>(slotConfigurations?.length ?? 0)

      slotConfigurations &&
        slotConfigurations.forEach((slotConfig, slotConfigIndex) => {
          selectedConfigs[dateIndex][slotConfigIndex] = false
        })
    })

    setSelectedConfigurations(selectedConfigs)
  }

  const getStatusName = (statusId: string) => {
    return slotStatuses.find((s) => s.id === statusId)?.statusName ?? AVAILABLE_STATUS
  }

  const onChangeStatus = (statusId: string) => {
    const changes = getSelectedConfigurations()

    if (changes.length > 0) {
      setIsSlotUpdating(true)
      bulkSlotsUpdate(
        {
          changes,
          slotStatusId: statusId === '' ? undefined : statusId,
          status: getStatusName(statusId),
          plantIds: getAllPlants()
        },
        {
          onError: () => {
            setIsSlotUpdating(false)
          },
          onSuccess: (data, variables) => {
            slotQueryClient.setQueryData(
              ['slots'],
              mergeSlots(slots, data, variables, slotConfigurations)
            )
            initializeSelectedConfigurations()
            setIsSlotUpdating(false)
            setIsBulkChangeEnabled(false)
          }
        }
      )
    }
  }

  const onChangeTheme = (theme: string) => {
    const ratio = getThemeRatio(theme)

    setTimeout(() => {
      setCurrentThemeRatio(ratio)
      setCurrentTheme(theme)
    }, 50)

    localStorage.setItem(CURRENT_THEME_KEY, theme)

    setRenderingAfterThemeChange(true)
  }

  const getThemeRatio = (theme: string) => {
    switch (theme) {
      case 'SMALL':
        return 0.6
      case 'EXTRA_SMALL':
        return 0.5
      default:
        return 1
    }
  }

  const onSelectedConfigurationsChange = (
    value: boolean,
    dateIndex: number,
    configIndex: number
  ) => {
    const selectedConfigs = [...selectedConfigurations]
    selectedConfigs[dateIndex][configIndex] = value
    setSelectedConfigurations(selectedConfigs)

    const isSelected = selectedConfigs.flat().some((item) => item === true)
    setIsBulkChangeEnabled(isSelected)
  }

  const showSlotsPerDay = (
    slots: Slot[] | undefined,
    slotConfig: SlotConfiguration,
    plantId: string,
    date: Date,
    isSelected: boolean
  ) => {
    if (!slotStatuses) return undefined

    const slot = mostRecentSlot(slots, slotConfig.id, date)
    return slot ? (
      <SlotSelect
        key={`${slotConfig.slotName}-${slot.status}-${plantId}-${format(
          parseISO(slot.date),
          'dd-MM-yyyy'
        )}`}
        slotStatus={slot.slotStatus}
        statuses={slotStatuses}
        isUpdate={true}
        slotId={slot.id}
        slotName={slotConfig.slotName}
        slotConfigId={slotConfig.id}
        plantId={slot.plantId}
        date={date}
        isAlwaysFull={slotConfig.isAlwaysFull}
        disabled={isSlotsFetching}
        isSelected={isSelected}
        themeRatio={currentThemeRatio}
      />
    ) : (
      <SlotSelect
        key={`${slotConfig.slotName}-available-${plantId}-${format(date, 'dd-MM-yyyy')}`}
        plantId={plantId}
        slotName={slotConfig.slotName}
        slotConfigId={slotConfig.id}
        statuses={slotStatuses}
        isAlwaysFull={slotConfig.isAlwaysFull}
        date={date}
        disabled={isSlotsFetching}
        isSelected={isSelected}
        themeRatio={currentThemeRatio}
      />
    )
  }

  const renderSlotByPlant = (slotByPlantId: SlotByPlantId, date: Date, dateIndex: number) => {
    const {plantId, slots} = slotByPlantId

    if (isEmpty(slots)) {
      return (
        <div key={`slots-${plantId}-${format(date, 'dd-MM-yyyy')}`}>
          <Grid item container style={{height: ROW_HEIGHT * currentThemeRatio}}>
            {slotStatuses &&
              slotConfigurations &&
              slotConfigurations.map((slotConfig, slotConfigIndex) => (
                <SlotSelect
                  key={`${slotConfig.slotName}-available-${plantId}-${format(date, 'dd-MM-yyyy')}`}
                  slotName={slotConfig.slotName}
                  statuses={slotStatuses}
                  slotConfigId={slotConfig.id}
                  plantId={plantId}
                  date={date}
                  disabled={isSlotsFetching}
                  isAlwaysFull={slotConfig.isAlwaysFull}
                  isSelected={selectedConfigurations[dateIndex][slotConfigIndex]}
                  themeRatio={currentThemeRatio}
                />
              ))}
          </Grid>
        </div>
      )
    }

    return (
      <div key={`slots-${plantId}-${format(date, 'dd-MM-yyyy')}`}>
        <Grid item container style={{height: ROW_HEIGHT * currentThemeRatio}}>
          {slotConfigurations &&
            slotConfigurations.map((slotConfig, slotConfigIndex) =>
              showSlotsPerDay(
                slots,
                slotConfig,
                plantId,
                date,
                selectedConfigurations[dateIndex][slotConfigIndex]
              )
            )}
        </Grid>
      </div>
    )
  }

  if (isPlantsLoading || isSlotsLoading || isSlotConfigurationLoading || isSlotStatusesLoading) {
    return <Loader height="60vh" color="primary.contrastText" />
  }

  return (
    <SlotsThemeContext.Provider value={currentTheme}>
      <Box className={isSlotUpdating || renderingAfterThemeChange ? c.isUpdating : ''}>
        <StatusSelectContainer>
          <ThemeSelect
            isUpdating={renderingAfterThemeChange}
            onClose={onChangeTheme}
            themes={SlotsApplicationThemes}
            currentTheme={currentTheme}
          />
          <StatusSelect
            isDisabled={!isBulkChangeEnabled}
            isUpdating={isSlotUpdating}
            onClose={onChangeStatus}
            statuses={slotStatuses}
          />
        </StatusSelectContainer>
        <Paper>
          <SlotsGridContainer>
            <SlotsGrid>
              <SlotsGridFixedCol themeratio={currentThemeRatio}>
                <SlotsGridFixedHeader>
                  <TableHeadColumns
                    item
                    container
                    justifyContent="space-between"
                    alignItems="end"
                    paddingX={2}
                    paddingBottom={1}
                    themeratio={currentThemeRatio}
                  >
                    <BoldHeadCell item data-test-id="plantsNameColumn">
                      {t('table.plantName')}
                    </BoldHeadCell>
                    <BoldHeadCell item data-test-id="plantsIdColumn">
                      {t('table.plantCode')}
                    </BoldHeadCell>
                  </TableHeadColumns>
                </SlotsGridFixedHeader>

                {plants &&
                  !isEmpty(plants) &&
                  plants.map((plant, index) => (
                    <SlotsGridItem key={plant.plantId} themeratio={currentThemeRatio}>
                      <PlantColumn
                        item
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        wrap="nowrap"
                        paddingX={2}
                        index={index}
                        count={plants.length}
                        themeratio={currentThemeRatio}
                      >
                        <Grid item alignContent="left" className={c.plantNameWrapper}>
                          <Bold small data-test-id="plantName" className={c.plantName}>
                            {plant.plantName}
                          </Bold>
                        </Grid>
                        <Grid item alignContent="right">
                          <Typography variant="body2" data-test-id="plantId">
                            {plant.plantId}
                          </Typography>
                        </Grid>
                      </PlantColumn>
                    </SlotsGridItem>
                  ))}
              </SlotsGridFixedCol>

              {slotConfigurations &&
                dates.map((date, dateIndex) => (
                  <SlotsGridBorderCol
                    style={{
                      width: DATE_CELL_WIDTH * slotConfigurations?.length * currentThemeRatio + 4
                    }}
                    key={format(date, 'dd-MM-yyyy')}
                  >
                    <SlotsGridFixedHeader>
                      <SlotsTableDateCell
                        item
                        container
                        loading={isSlotsFetching ? 1 : 0}
                        textAlign="center"
                        display="block"
                      >
                        <Grid item>
                          <Typography variant="body2" data-test-id="dateTableDay">
                            {format(date, 'iii')}
                          </Typography>
                        </Grid>

                        <Grid item>
                          <Bold style={{fontSize: 16}} data-test-id="dateTableDate">
                            {format(date, 'dd MMM yyyy')}
                          </Bold>
                        </Grid>
                      </SlotsTableDateCell>
                      <Grid
                        item
                        container
                        columns={2}
                        wrap="nowrap"
                        height={40}
                        alignItems={'center'}
                      >
                        {slotConfigurations.map((slotConfig, slotConfigIndex) =>
                          slotConfig.isAlwaysFull || currentTheme === 'EXTRA_SMALL' ? (
                            <Typography
                              key={`configurationLabel-${dateIndex}-${slotConfigIndex}`}
                              style={{
                                width: DATE_CELL_WIDTH * currentThemeRatio,
                                fontSize: 10 * currentThemeRatio * FONT_RATIO,
                                textAlign: 'center'
                              }}
                              data-test-id={`dateTableColumn${slotConfig.slotName}`}
                              noWrap
                            >{`${slotConfig.slotStart}-${slotConfig.slotEnd}`}</Typography>
                          ) : (
                            <SlotsFormControlLabel
                              key={`configurationLabel-${dateIndex}-${slotConfigIndex}`}
                              labelPlacement="start"
                              themeratio={currentThemeRatio}
                              slotsthemename={currentTheme}
                              control={
                                <Checkbox
                                  key={`configurationValue-${dateIndex}-${slotConfigIndex}`}
                                  icon={<RadioButtonUncheckedIcon />}
                                  checkedIcon={<CheckCircleIcon />}
                                  checked={selectedConfigurations[dateIndex][slotConfigIndex]}
                                  onChange={(e) =>
                                    onSelectedConfigurationsChange(
                                      e.target.checked,
                                      dateIndex,
                                      slotConfigIndex
                                    )
                                  }
                                />
                              }
                              label={
                                <Typography
                                  style={{fontSize: 10 * currentThemeRatio * FONT_RATIO}}
                                  data-test-id={`dateTableColumn${slotConfig.slotName}`}
                                  noWrap
                                >{`${slotConfig.slotStart}-${slotConfig.slotEnd}`}</Typography>
                              }
                            />
                          )
                        )}
                      </Grid>
                    </SlotsGridFixedHeader>

                    {slotsByPlants &&
                      !isEmpty(slotsByPlants) &&
                      slotsByPlants.map((slotByPlantId) =>
                        renderSlotByPlant(slotByPlantId, date, dateIndex)
                      )}
                  </SlotsGridBorderCol>
                ))}
            </SlotsGrid>
          </SlotsGridContainer>
        </Paper>
      </Box>
    </SlotsThemeContext.Provider>
  )
}
