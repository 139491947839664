import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {Box, styled, Typography} from '@mui/material'
import Popover from '@mui/material/Popover'
import * as React from 'react'

import {SlotTheme} from '../../common/types'
import {STATUS_SELECT_HEIGHT, STATUS_SELECT_WIDTH} from '../declarations/constants'

import Spinner from './Spinner'

const ThemesFontSize = {
  LARGE: 18,
  SMALL: 14,
  EXTRA_SMALL: 12
}

const ChangeThemeContainer = styled(Box)({
  cursor: 'pointer',
  background: 'white',
  borderRadius: '4px',
  padding: '8px',
  width: `${STATUS_SELECT_WIDTH}px`,
  height: `${STATUS_SELECT_HEIGHT}px`,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
})

const ChangeThemePopover = styled(Popover)({
  width: `${STATUS_SELECT_WIDTH}px`
})

const ThemeItemsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '10px',
  width: `${STATUS_SELECT_WIDTH}px`
})

const ThemeItemContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  width: `${STATUS_SELECT_WIDTH}px`
})

const ThemeIndicator = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  width: '20px',
  height: '20px',
  margin: '10px'
})

interface ThemeSelectProps {
  isUpdating: boolean
  currentTheme: string
  themes: SlotTheme[]
  onClose: (theme: string) => void
}

export const ThemeSelect: React.FC<ThemeSelectProps> = ({
  onClose,
  isUpdating,
  currentTheme,
  themes
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleItemClick = (value: string) => {
    setAnchorEl(null)
    onClose(value)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const currentThemeItem = themes.find((theme) => theme.id === currentTheme)

  return (
    <>
      <ChangeThemeContainer onClick={handleClick}>
        <Typography color="textPrimary">{currentThemeItem?.themeName}</Typography>
        {!isUpdating ? <ExpandMoreIcon color="primary" /> : <Spinner />}
      </ChangeThemeContainer>
      <ChangeThemePopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        PaperProps={{
          style: {minWidth: STATUS_SELECT_WIDTH}
        }}
      >
        <ThemeItemsContainer display="flex">
          {themes.map((theme) => {
            return (
              <ThemeItemContainer
                key={`theme-${theme.themeName}`}
                onClick={() => handleItemClick(theme.id)}
              >
                <ThemeIndicator>
                  <Typography style={{fontSize: ThemesFontSize[theme.id]}}>Aa</Typography>
                </ThemeIndicator>
                {theme.themeName}
              </ThemeItemContainer>
            )
          })}
        </ThemeItemsContainer>
      </ChangeThemePopover>
    </>
  )
}
