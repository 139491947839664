import {loginFlow} from '@hconnect/common/authentication/loginClasses'

import {SlotUser} from './types'

export const isUserLoggedIn = (user?: SlotUser): boolean => {
  return Boolean(user?.userId)
}

export const startLoginProcess = () => loginFlow.startLoginProcess()
export const startLogoutProcess = () => loginFlow.startLogoutProcess()
