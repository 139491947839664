import {ErrorMessage, CardBox, PageContainer} from '@hconnect/uikit/src/lib2'
import React from 'react'
import {useTranslation} from 'react-i18next'

export const ErrorPage: React.FC = () => {
  const {t} = useTranslation()

  return (
    <PageContainer>
      <CardBox>
        <ErrorMessage>{t('errors.pageNotFound')}</ErrorMessage>
      </CardBox>
    </PageContainer>
  )
}
