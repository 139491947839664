import {trackEvent as track} from '@hconnect/common/logging/Analytics'

type TrackEventData = {
  [key: string]: unknown
}

type TrackEventKey = 'hubOrderIntakeSlotFrontendError'

export const trackEvent = (key: TrackEventKey, obj: TrackEventData = {}) =>
  track(key, {
    product: 'slot',
    ...obj
  })
