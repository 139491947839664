import {format, formatISO, parseISO} from 'date-fns'
import {v4 as uuidV4} from 'uuid'

import {Slot, SlotsBulkUpdate, SlotConfiguration} from '../common/types'

export const mostRecentSlot = (
  slots: Slot[] | undefined,
  slotConfigId: string,
  date: Date
): Slot | undefined => {
  const slot = slots
    ?.filter(
      (slot) =>
        formatISO(new Date(parseISO(slot.date)), {representation: 'date'}) ===
        formatISO(date, {representation: 'date'})
    )
    .filter((slot) => slot.slotConfig?.id === slotConfigId)
    .sort((a, b) => new Date(parseISO(b.date)).getDate() - new Date(parseISO(a.date)).getDate())[0]
  return slot
}

export const existsSlot = (slot: Slot, variables: SlotsBulkUpdate) => {
  const slotDate = format(parseISO(slot.date), 'MM-dd-yyyy')

  const changesForDay = variables.changes.find((item) => item.date === slotDate)
  if (changesForDay) {
    return changesForDay.slotConfigIds.some((item) => item === slot.slotConfig?.id)
  }

  return false
}

const isEmptyId = (id: string) => {
  return id === '00000000-0000-0000-0000-000000000000'
}

export const mergeSlots = (
  originalSlots?: Slot[],
  newSlots?: Slot[],
  variables?: SlotsBulkUpdate,
  slotConfigurations?: SlotConfiguration[]
) => {
  let mergedSlots: Slot[] = []

  if (originalSlots) {
    mergedSlots = [...originalSlots]
  }

  if (variables) mergedSlots = mergedSlots.filter((slot) => !existsSlot(slot, variables))

  if (newSlots) {
    const resolvedNewSlots = [...newSlots]

    for (let i = 0; i < resolvedNewSlots.length; i++) {
      if (isEmptyId(resolvedNewSlots[i].id)) resolvedNewSlots[i].id = uuidV4()

      const slotConfig =
        slotConfigurations &&
        slotConfigurations.find((config) => config.id === resolvedNewSlots[i].slotConfigId)
      if (slotConfig) resolvedNewSlots[i].slotConfig = {...slotConfig}
    }

    mergedSlots = mergedSlots.concat(resolvedNewSlots)
  }

  return mergedSlots
}
