import {makeStyles} from '@material-ui/core/styles'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {Box, styled, Typography} from '@mui/material'
import Popover from '@mui/material/Popover'
import * as React from 'react'
import {useTranslation} from 'react-i18next'

import {SlotStatus} from '../../common/types'
import {STATUS_SELECT_HEIGHT, STATUS_SELECT_WIDTH} from '../declarations/constants'

import {statusColor} from './SlotSelect'
import Spinner from './Spinner'

const useStyles = makeStyles(() => ({
  isDisabled: {
    pointerEvents: 'none',
    opacity: 0.8
  }
}))

const ChangeStatusContainer = styled(Box)({
  cursor: 'pointer',
  background: 'white',
  borderRadius: '4px',
  padding: '8px',
  width: `${STATUS_SELECT_WIDTH}px`,
  height: `${STATUS_SELECT_HEIGHT}px`,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
})

const ChnageStatusPopover = styled(Popover)({
  width: `${STATUS_SELECT_WIDTH}px`
})

const StatusItemsContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  padding: '10px',
  width: `${STATUS_SELECT_WIDTH}px`
})

const StatusItemContainer = styled(Box)({
  display: 'inline-flex',
  alignItems: 'center',
  cursor: 'pointer',
  width: `${STATUS_SELECT_WIDTH}px`
})

const StatusIndicator = styled(Box)({
  width: '20px',
  height: '20px',
  margin: '10px'
})

interface StatusSelectProps {
  isUpdating: boolean
  isDisabled: boolean
  statuses: SlotStatus[]
  onClose: (status: string) => void
}

export const StatusSelect: React.FC<StatusSelectProps> = ({
  onClose,
  isUpdating,
  isDisabled,
  statuses
}) => {
  const {t} = useTranslation()
  const c = useStyles()
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleItemClick = (value: string) => {
    setAnchorEl(null)
    onClose(value)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  return (
    <>
      <ChangeStatusContainer onClick={handleClick} className={isDisabled ? c.isDisabled : ''}>
        <Typography color="textPrimary">{t('changeStatus')}</Typography>
        {!isUpdating ? <ExpandMoreIcon color="primary" /> : <Spinner />}
      </ChangeStatusContainer>
      <ChnageStatusPopover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        PaperProps={{
          style: {minWidth: STATUS_SELECT_WIDTH}
        }}
      >
        <StatusItemsContainer display="flex">
          {statuses.map((status) => {
            return (
              <StatusItemContainer
                key={`status-${status.statusName}`}
                onClick={() => handleItemClick(status.id)}
              >
                <StatusIndicator style={{background: statusColor[status.statusName]}} />
                {status.translatedStatusName}
              </StatusItemContainer>
            )
          })}
        </StatusItemsContainer>
      </ChnageStatusPopover>
    </>
  )
}
