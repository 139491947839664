import React from 'react'
import {Helmet} from 'react-helmet-async'

interface HelmetHeadProps {
  title: string
  description: string
}

export const HelmetHead: React.FC<HelmetHeadProps> = ({title, description}) => {
  return (
    <Helmet title={title}>
      <meta name="description" content={description} />
    </Helmet>
  )
}
