import {SlotsBulkUpdate, Slot, SlotUpdateDTO} from '../types'

import {api, API_PATH} from './api'

interface UpdateSlot {
  slot: SlotUpdateDTO
  isUpdate?: boolean
}

export const mutations = {
  updateSlot: async ({slot}: UpdateSlot): Promise<Slot> => {
    const path = `${API_PATH}/slots`
    const response = await api.post<Slot>(path, slot)
    return response.data
  },
  bulkUpdateSlot: async (bulkChange: SlotsBulkUpdate): Promise<Slot[]> => {
    const path = `${API_PATH}/slots/bulkUpdate`
    const response = await api.post<Slot[]>(path, bulkChange)
    return response.data
  }
} as const
