import {ApiProvider} from '@hconnect/common/hooks/useApi'
import {HCTheme, NotificationProvider} from '@hconnect/uikit'
import {CssBaseline, ThemeProvider} from '@mui/material'
import i18next from 'i18next'
import React from 'react'
import {HelmetProvider} from 'react-helmet-async'
import {I18nextProvider, useTranslation} from 'react-i18next'
import {ReactQueryDevtools} from 'react-query/devtools'
import {BrowserRouter} from 'react-router-dom'

import {api, publicApi} from './common/react-query/api'
import {QueryProvider} from './common/react-query/QueryProvider'
import {HelmetHead} from './components/helmet/HelmetHead'
import {Main} from './Main'
import {AuthProvider} from './providers/AuthProvider'

export const App: React.FC = () => {
  const {t} = useTranslation()
  return (
    <ApiProvider secureApi={api} publicApi={publicApi}>
      <HelmetProvider>
        <HelmetHead title={t('metaTitle')} description={t('appDescription')} />
        <ThemeProvider theme={HCTheme}>
          <CssBaseline />
          <QueryProvider>
            <ReactQueryDevtools initialIsOpen={false} />
            <I18nextProvider i18n={i18next}>
              <NotificationProvider>
                <AuthProvider>
                  <BrowserRouter>
                    <Main />
                  </BrowserRouter>
                </AuthProvider>
              </NotificationProvider>
            </I18nextProvider>
          </QueryProvider>
        </ThemeProvider>
      </HelmetProvider>
    </ApiProvider>
  )
}
