import {useNotification} from '@hconnect/uikit'
import {Button} from '@mui/material'
import React from 'react'
import {useTranslation} from 'react-i18next'
import {QueryKey} from 'react-query'

import {slotQueryClient} from '../QueryProvider'

type NotificationData = {
  plantId?: string
  date?: string
  slotName?: string
}

export const useErrorHandling = () => {
  const {notify} = useNotification()
  const {t} = useTranslation()

  const handleNetworkError = (queryKey: QueryKey) => {
    notify('error', t('error.networkError'), {
      persist: true,
      action: (
        <Button onClick={() => void slotQueryClient.invalidateQueries([queryKey])}>
          {t('retry')}
        </Button>
      )
    })
  }

  const handleUnauthorized = (trackEvent?: () => void) => {
    trackEvent && trackEvent()
    notify('error', t('error.unauthorized'), {
      persist: true,
      action: <Button onClick={() => window.location.reload()}>{t('reload')}</Button>
    })
  }

  const handleApiUnavailable = (trackEvent?: () => void) => {
    notify('error', t('error.slotSelectionUnavailable'))
    trackEvent && trackEvent()
  }

  const handleNoPermissions = (trackEvent?: () => void) => {
    trackEvent && trackEvent()
    notify('error', t('error.noPermissions'))
  }

  const handleBadRequest = (notificationData: NotificationData, trackEvent?: () => void) => {
    notify('error', t('error.slotSelectionBadRequest', notificationData))
    trackEvent && trackEvent()
  }

  const handleExistingSlot = (
    notificationData: NotificationData,
    queryKey: QueryKey,
    trackEvent?: () => void
  ) => {
    trackEvent && trackEvent()
    notify('error', t('error.slotAlreadyExists', notificationData), {
      persist: true,
      action: (
        <Button onClick={() => void slotQueryClient.invalidateQueries([queryKey])}>
          {t('reload')}
        </Button>
      )
    })
  }

  return {
    handleNetworkError,
    handleUnauthorized,
    handleApiUnavailable,
    handleNoPermissions,
    handleBadRequest,
    handleExistingSlot
  } as const
}
